import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/index.scss'
import { ContextProvider } from './state';
import { BrowserRouter } from 'react-router-dom'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
   <BrowserRouter>
      <ContextProvider>
         <App />
      </ContextProvider>
   </BrowserRouter>
)

