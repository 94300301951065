export const ACTIONS = {
   SET: 1,
   CLOSE_DIALOG: 2,
   OPEN_DIALOG: 3,
   PUSH: 4,
   POP: 5,
   REFRESH_DATA: 5,
}


