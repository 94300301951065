export const svgs = {
   hamburger:
      <svg viewBox="0 0 800 600" >
         <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" className="top_bar" />
         <path d="M300,320 L540,320" className="middle_bar" />
         <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" className="bottom_bar" transform="translate(480, 320) scale(1, -1) translate(-480, -318)" />
      </svg>,
   details: <svg className='icon' id='modal-opener'
      viewBox="0 0 16 14" fill="#da983c" >
      <path d="M3 5H5V6H3V5ZM6 5H13V6H6V5ZM3 7H5V8H3V7ZM6 7H13V8H6V7ZM3 9H5V10H3V9ZM6 9H13V10H6V9Z" fill="#da983c" />
      <path d="M0 0V14H16V0H0ZM15 13H1V3H15V13ZM15 2H14V1H15V2Z" fill="#da983c" />
   </svg>,
   save: <svg className='icon' id='save'
      viewBox="0 0 61 61" fill="none" >
      <path d="M58.7501 13.6329L46.5233 1.40615C45.9594 0.842187 45.2676 0.428613 44.5156 0.203027V0H2.40625C1.07529 0 0 1.07529 0 2.40625V57.75C0 59.081 1.07529 60.1562 2.40625 60.1562H57.75C59.081 60.1562 60.1562 59.081 60.1562 57.75V17.0317C60.1562 15.7534 59.6524 14.5353 58.7501 13.6329ZM20.4531 5.41406H39.7031V13.2344H20.4531V5.41406ZM54.7422 54.7422H5.41406V5.41406H15.6406V15.6406C15.6406 16.9716 16.7159 18.0469 18.0469 18.0469H42.1094C43.4403 18.0469 44.5156 16.9716 44.5156 15.6406V7.05332L54.7422 17.2799V54.7422ZM30.0781 24.8145C24.1001 24.8145 19.25 29.6646 19.25 35.6426C19.25 41.6206 24.1001 46.4707 30.0781 46.4707C36.0562 46.4707 40.9062 41.6206 40.9062 35.6426C40.9062 29.6646 36.0562 24.8145 30.0781 24.8145ZM30.0781 41.6582C26.7545 41.6582 24.0625 38.9662 24.0625 35.6426C24.0625 32.3189 26.7545 29.627 30.0781 29.627C33.4018 29.627 36.0938 32.3189 36.0938 35.6426C36.0938 38.9662 33.4018 41.6582 30.0781 41.6582Z" fill="#da983c" />
   </svg>,
   clear: <svg className='icon' id='clear'
      viewBox="0 0 40 40"
      fill="none"
   >
      <path d="M38.8643 34.885C39.3926 35.4133 39.6895 36.1299 39.6895 36.8771C39.6895 37.6244 39.3926 38.341 38.8643 38.8693C38.3359 39.3977 37.6193 39.6945 36.8721 39.6945C36.1249 39.6945 35.4082 39.3977 34.8799 38.8693L19.9994 23.9842L5.11425 38.8646C4.58589 39.393 3.86928 39.6898 3.12207 39.6898C2.37485 39.6898 1.65824 39.393 1.12988 38.8646C0.601518 38.3363 0.304688 37.6197 0.304688 36.8725C0.304687 36.1252 0.601518 35.4086 1.12988 34.8803L16.015 19.9998L1.13457 5.11464C0.606205 4.58628 0.309375 3.86967 0.309375 3.12246C0.309375 2.37524 0.606205 1.65863 1.13457 1.13027C1.66293 0.601908 2.37954 0.305078 3.12675 0.305078C3.87397 0.305078 4.59058 0.601908 5.11894 1.13027L19.9994 16.0154L34.8846 1.12792C35.4129 0.599564 36.1295 0.302734 36.8768 0.302734C37.624 0.302734 38.3406 0.599564 38.8689 1.12792C39.3973 1.65629 39.6941 2.3729 39.6941 3.12011C39.6941 3.86733 39.3973 4.58394 38.8689 5.1123L23.9838 19.9998L38.8643 34.885Z" fill="#da983c" />
   </svg>,
   plus: <svg className='icon' id='plus'
      viewBox="0 0 63 65" fill="none" >
      <path d="M28.834 0.507812L34.166 0.507812C34.64 0.507812 34.877 0.744792 34.877 1.21875V63.7812C34.877 64.2552 34.64 64.4922 34.166 64.4922H28.834C28.36 64.4922 28.123 64.2552 28.123 63.7812L28.123 1.21875C28.123 0.744792 28.36 0.507812 28.834 0.507812Z" fill="#da983c" />
      <path d="M1.64062 29.123L61.3594 29.123C61.8333 29.123 62.0703 29.36 62.0703 29.834V35.166C62.0703 35.64 61.8333 35.877 61.3594 35.877H1.64063C1.16667 35.877 0.929688 35.64 0.929688 35.166L0.929688 29.834C0.929688 29.36 1.16667 29.123 1.64062 29.123Z" fill="#da983c" />
   </svg>,
   trashBin: <svg className='icon' id='trash-bin' viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2551_2684)">
         <path d="M8 -3H11C11.6 -3 12 -2.6 12 -2V-1H-1V-2C-1 -2.6 -0.5 -3 0 -3H3C3.2 -4.1 4.3 -5 5.5 -5C6.7 -5 7.8 -4.1 8 -3ZM4 -3H7C6.8 -3.6 6.1 -4 5.5 -4C4.9 -4 4.2 -3.6 4 -3ZM0 0H11L10.1 10.1C10.1 10.6 9.6 11 9.1 11H1.9C1.4 11 1 10.6 0.9 10.1L0 0Z" fill="#da983c" />
      </g>
      <defs>
         <clipPath id="clip0_2551_2684">
            <rect width="11" height="11" fill="white" />
         </clipPath>
      </defs>
   </svg>,
   trashCover: <svg className='icon' id='trash-cover' viewBox="0 0 13 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2551_2681)">
         <path d="M9 2H12C12.6 2 13 2.4 13 3V4H0V3C0 2.4 0.5 2 1 2H4C4.2 0.9 5.3 0 6.5 0C7.7 0 8.8 0.9 9 2ZM5 2H8C7.8 1.4 7.1 1 6.5 1C5.9 1 5.2 1.4 5 2ZM1 5H12L11.1 15.1C11.1 15.6 10.6 16 10.1 16H2.9C2.4 16 2 15.6 1.9 15.1L1 5Z" fill="#da983c" />
      </g>
      <defs>
         <clipPath id="clip0_2551_2681">
            <rect width="13" height="4" fill="white" />
         </clipPath>
      </defs>
   </svg>
   ,
   phone:
   <svg className='icon' viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5569 10.906L12.1019 11.359C12.1019 11.359 11.0189 12.435 8.06386 9.49698C5.10886 6.55898 6.19186 5.48298 6.19186 5.48298L6.47786 5.19698C7.18486 4.49498 7.25186 3.36698 6.63486 2.54298L5.37486 0.859979C4.61086 -0.160021 3.13586 -0.29502 2.26086 0.57498L0.690856 2.13498C0.257856 2.56698 -0.032144 3.12498 0.002856 3.74498C0.092856 5.33198 0.810856 8.74498 4.81486 12.727C9.06186 16.949 13.0469 17.117 14.6759 16.965C15.1919 16.917 15.6399 16.655 16.0009 16.295L17.4209 14.883C18.3809 13.93 18.1109 12.295 16.8829 11.628L14.9729 10.589C14.1669 10.152 13.1869 10.28 12.5569 10.906Z" fill="black" />
   </svg>,
email:
   <svg className='icon' width="24" height="24" viewBox="0 0 24 24" fill="none" >
      <path d="M19.07 13.88L13 19.94V22H15.06L21.12 15.93M22.7 13.58L21.42 12.3C21.3718 12.247 21.3131 12.2046 21.2478 12.1753C21.1824 12.1461 21.1116 12.1306 21.04 12.13C20.89 12.14 20.75 12.19 20.65 12.3L19.65 13.3L21.7 15.3L22.7 14.3C22.89 14.1 22.89 13.78 22.7 13.58ZM11 18H4V8L12 13L20 8V10H22V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H11V18ZM20 6L12 11L4 6H20Z" fill="white" />
   </svg>,
whatsapp:
   <svg className='icon' viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9_3)">
         <path d="M0.533447 12.3504C0.532861 14.4509 1.08599 16.5018 2.13774 18.3096L0.432861 24.4861L6.80317 22.8288C8.56513 23.7805 10.5393 24.2792 12.5454 24.2794H12.5506C19.1732 24.2794 24.5641 18.9322 24.5669 12.3598C24.5682 9.175 23.3194 6.18023 21.0504 3.92713C18.7819 1.67423 15.7648 0.43285 12.5501 0.431396C5.92681 0.431396 0.536279 5.7783 0.533545 12.3504" fill="url(#paint0_linear_9_3)" />
         <path d="M0.104492 12.3465C0.103809 14.5226 0.676758 16.6469 1.76602 18.5194L0 24.9173L6.59873 23.2006C8.41689 24.1842 10.464 24.7028 12.547 24.7036H12.5523C19.4125 24.7036 24.9971 19.1641 25 12.3564C25.0012 9.05717 23.7074 5.95475 21.3574 3.62093C19.0071 1.2874 15.8821 0.00135659 12.5523 0C5.69101 0 0.107227 5.53876 0.104492 12.3465ZM4.03428 18.1969L3.78789 17.8088C2.75215 16.1747 2.20547 14.2863 2.20625 12.3473C2.2084 6.68886 6.84941 2.08527 12.5563 2.08527C15.3199 2.08643 17.9172 3.15543 19.8707 5.09496C21.8241 7.03469 22.899 9.61318 22.8983 12.3556C22.8958 18.0141 18.2547 22.6182 12.5523 22.6182H12.5482C10.6915 22.6172 8.87051 22.1225 7.28242 21.1875L6.90449 20.9651L2.98867 21.9838L4.03428 18.1969Z" fill="url(#paint1_linear_9_3)" />
         <path d="M9.44116 7.18489C9.20815 6.67103 8.96294 6.66066 8.74136 6.65165C8.55991 6.6439 8.35249 6.64448 8.14526 6.64448C7.93784 6.64448 7.60083 6.7219 7.31597 7.03053C7.03081 7.33944 6.22729 8.08595 6.22729 9.60427C6.22729 11.1226 7.34185 12.59 7.49722 12.7961C7.65278 13.0018 9.64888 16.2173 12.8102 17.4544C15.4375 18.4824 15.9722 18.2779 16.5424 18.2264C17.1127 18.175 18.3827 17.48 18.6417 16.7594C18.901 16.0389 18.901 15.4212 18.8233 15.2922C18.7456 15.1636 18.5381 15.0863 18.2271 14.9321C17.9161 14.7778 16.3869 14.0311 16.1018 13.9281C15.8166 13.8252 15.6093 13.7738 15.4019 14.0829C15.1945 14.3914 14.5989 15.0863 14.4173 15.2922C14.236 15.4985 14.0544 15.5241 13.7435 15.3698C13.4323 15.2149 12.4306 14.8894 11.2422 13.8382C10.3176 13.0202 9.69341 12.01 9.51196 11.701C9.33052 11.3924 9.49253 11.2252 9.64849 11.0714C9.78823 10.9331 9.95962 10.711 10.1153 10.5309C10.2704 10.3507 10.3221 10.2221 10.4258 10.0163C10.5296 9.81027 10.4777 9.63004 10.4 9.47568C10.3221 9.32132 9.71772 7.79506 9.44116 7.18489Z" fill="white" />
      </g>
      <defs>
         <linearGradient id="paint0_linear_9_3" x1="1207.14" y1="2405.91" x2="1207.14" y2="0.431396" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1FAF38" />
            <stop offset="1" stopColor="#60D669" />
         </linearGradient>
         <linearGradient id="paint1_linear_9_3" x1="1250" y1="2491.73" x2="1250" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="white" />
         </linearGradient>
         <clipPath id="clip0_9_3">
            <rect width="25" height="25" fill="white" />
         </clipPath>
      </defs>
   </svg>
,
}